import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { Link } from 'react-scroll';
// layout
import Layout from 'components/Layout';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';

import servicesPageStyle from 'assets/jss/material-kit-react/views/servicesPage';

// Sections for this page
import ServicesSection from '../components/ServicesPage/ServicesSection';

const ServicesPage = ({ classes, data }) => {
  if (!data) return null;

  const content = data.prismicServicesPage.data;
  const { lang } = data.prismicServicesPage;
  const alternateLanguages = data.prismicServicesPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title="Services provided by our travel agency | Servitours" />
      <Parallax filter image={content.hero_image.url} className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{RichText.asText(content.headline.raw)}</h1>
              <h4 className={classes.subtitle}>{RichText.asText(content.description.raw)}</h4>
              <br />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <div id="scrollDownButton" className={classes.scrollDownButton}>
                <Link
                  className="js-scroll-trigger"
                  to="services-section"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  <span className={classes.blueText} />
                  <span className={classes.whiteText} />
                  <span className={classes.redText} />
                </Link>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ServicesSection data={content.service} />
        </div>
      </div>
    </Layout>
  );
};

// Query for the Services page content in Prismic
export const query = graphql`
  query ServicesQuery($lang: String = "fr-fr") {
    prismicServicesPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        description {
          raw
        }
        headline {
          raw
        }
        hero_image {
          thumbnails {
            mobile {
              url
            }
          }
          url
        }
        service {
          image {
            url
          }
          service_description {
            raw
          }
          service_title {
            raw
          }
        }
      }
      lang
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(servicesPageStyle)(ServicesPage);
