import { cardHeader, cardTitle, title } from 'assets/jss/material-kit-react';
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles';

const servicesStyle = {
  section: {
    padding: '20px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
  ...imagesStyles,
  cardHeader: {
    ...cardHeader,
    fontWeight: 600,
  },
  cardTitle,
  textMuted: {
    color: '#6c757d',
  },
  cardBody: {
    maxHeight: '100px',
    overflow: 'auto',
  },
};

export default servicesStyle;
