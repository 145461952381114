import { container, title } from 'assets/jss/material-kit-react';

const servicesPageStyle = theme => ({
  container: {
    zIndex: '12',
    color: '#fff',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#fff',
    textDecoration: 'none',
    textShadow: '2px 0 10px black',
    fontFamily: 'Great Vibes',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      marginTop: '0px',
    },
  },
  subtitle: {
    fontSize: '1.313rem',
    margin: '10px 0 0',
    display: 'inline-block',
    position: 'relative',
    textShadow: '2px 5px 5px black',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
  },
  scrollDownButton: {
    '& a': {
      paddingTop: '8px',
    },
    '& a span': {
      position: 'absolute',
      top: '75%',
      left: '50%',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
      borderLeft: '2px solid',
      borderBottom: '2px solid',
      transform: 'rotate(-45deg)',
      animation: '$scrollDownAnimation 2s infinite',
      opacity: 0,
      boxSizing: 'border-box',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '12px',
      },
    },
    '& a span:nth-of-type(1)': {
      animationDelay: '0s',
    },
    '& a span:nth-of-type(2)': {
      top: 'calc(75% + 16px)',
      animationDelay: '.15s',
      [theme.breakpoints.down('xs')]: {
        top: 'calc(75% + 8px)',
      },
    },
    '& a span:nth-of-type(3)': {
      top: 'calc(75% + 32px)',
      animationDelay: '.30s',
      [theme.breakpoints.down('xs')]: {
        top: 'calc(75% + 16px)',
      },
    },
  },
  blueText: {
    color: '#2196f3',
  },
  redText: {
    color: '#f44336',
  },
  whiteText: {
    color: '#fff',
  },
  main: {
    background: '#fff',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
  parallax: {
    [theme.breakpoints.down('xs')]: {
      height: '40vh',
    },
  },
  '@keyframes scrollDownAnimation': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});

export default servicesPageStyle;
