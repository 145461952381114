import React from 'react';
import { RichText } from 'prismic-reactjs';
import LazyLoad from 'react-lazyload';
import { isMobile } from 'react-device-detect';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Spinner from 'components/Spinner/Spinner';

import servicesStyle from 'assets/jss/material-kit-react/views/servicesPageSections/servicesStyle';

const ServicesSection = ({ classes, data }) => {
  if (data)
    return (
      <div className={classes.section}>
        <GridContainer justifyContent="center" id="services-section">
          {data.map(document =>
            document.service_title ? (
              <GridItem xs={12} sm={12} md={4} key={RichText.asText(document.service_title.raw)}>
                <LazyLoad height={100} once offset={[-100, 100]} placeholder={<Spinner />}>
                  <Card>
                    {document.image.url && !isMobile && (
                      <img
                        className={classes.imgCardTop}
                        src={document.image.url}
                        alt="Card-img-cap"
                      />
                    )}
                    {document.image.url && isMobile && (
                      <img
                        className={classes.imgCardTop}
                        src={document.image.url}
                        alt="Card-img-cap"
                      />
                    )}
                    <CardHeader color="beige" className={classes.cardHeader}>
                      {RichText.asText(document.service_title.raw)}
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <p>
                        {document.service_description
                          ? RichText.asText(document.service_description.raw)
                          : null}
                      </p>
                    </CardBody>
                  </Card>
                </LazyLoad>
              </GridItem>
            ) : null,
          )}
        </GridContainer>
      </div>
    );
  return null;
};

export default withStyles(servicesStyle)(ServicesSection);
